// import "reset-css";
// import "./src/style.css";
// import React from "react";
// import { RecoilRoot } from "recoil";

// export const wrapRootElement = ({ element }) => (
//   <RecoilRoot>{element}</RecoilRoot>
// );
require("reset-css");
require("./src/style.css");
const React = require("react");
const { RecoilRoot } = require("recoil");
const { AnimatePresence } = require("framer-motion");
exports.wrapRootElement = ({ element }) => (
  <AnimatePresence exitBeforeEnter>
    <RecoilRoot>{element}</RecoilRoot>
  </AnimatePresence>
);
