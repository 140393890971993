exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-how-to-order-normal-order-js": () => import("./../../../src/pages/how-to-order/normal-order.js" /* webpackChunkName: "component---src-pages-how-to-order-normal-order-js" */),
  "component---src-pages-how-to-order-online-shop-js": () => import("./../../../src/pages/how-to-order/online-shop.js" /* webpackChunkName: "component---src-pages-how-to-order-online-shop-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-new-information-js": () => import("./../../../src/pages/new-information.js" /* webpackChunkName: "component---src-pages-new-information-js" */),
  "component---src-pages-okashi-js": () => import("./../../../src/pages/okashi.js" /* webpackChunkName: "component---src-pages-okashi-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-user-guide-js": () => import("./../../../src/pages/user-guide.js" /* webpackChunkName: "component---src-pages-user-guide-js" */)
}

